<template>
  <EmailVerifyStatus
    status="Your email verification is successfull."
    success="true"
    :userID="userID"
    :error="showLoginError"
    :errorMessages="errorMessages"
  />
</template>

<script>
import EmailVerifyStatus from '@/common/EmailVerifyStatus.vue';

const {
  emailVerifyService,
} = require('@/services/user/user.service');

export default {
  name: 'EmailVerify',
  components: {
    EmailVerifyStatus,
  },
  data() {
    return {
      userID: this.$route.params.userID,
      showLoginError: false,
      errorMessages: '',
    };
  },
  created() {
    this.emailVerify();
  },
  methods: {
    async emailVerify() {
      try {
        const resetPasswordResponse = await emailVerifyService(
          this.userID,
        );
        if (resetPasswordResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.errorMessages = '';
        } else {
          throw new Error();
        }
      } catch (err) {
        this.errorMessages = 'Error Occured';
      }
    },
  },
};
</script>
