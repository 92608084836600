<template>
  <v-card class="dialog-modal">
    <v-card-title>
      <v-row d-flex align="start" justify="space-between">
        <h3>{{status}}</h3>
      </v-row>
    </v-card-title>
    <div class="dialog-container">
      <v-col>
      <Button
          type="flat"
          @buttonClicked="goToHomepage"
          value="Go to Homepage"
          color="primary"
          height="40px"
          width="100%"
      />
      </v-col>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'EmailVerifyStatus',
  props: ['status', 'success', 'userID', 'error', 'errorMessages'],
  components: {},
  data() {
    return {
      openDialog: true,
    };
  },
  methods: {
    goToHomepage() {
      window.open('https://timeflyz.co/', '_self');
    },
  },
};
</script>

<style lang="scss">
.tf-app{
  width: 30%;
  display: flex;
  align-items: center;
}
.dialog-modal {
  .v-card__title {
    padding: 17px 24px !important;
    border-bottom: 1px solid #c4c5cc !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .dialog-container {
    padding: 24px;
  }
  .text-field-container {
    margin-top: 15px;
  }
  .alert {
    ::v-deep .theme--light.v-btn.v-btn--icon:hover,
    ::v-deep .theme--light.v-btn.v-btn--icon:focus {
      color: $error-color !important;
    }
  }
}

	@media only screen and (max-width: 768px) {
  .dialog-modal {
    margin-top: 120px;
	  }
  }
</style>
